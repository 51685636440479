document.addEventListener("DOMContentLoaded", (event) => {
    const {
        initContactForm,
        initDemoExperienceForm,
        initHsChatOpen,
        manualSaveUserTraits,
        addManualUserTraitsFields,
        redirectIfNoEmail,
    } = WebflowCustomCode;

    initContactForm();
    initDemoExperienceForm();
    initHsChatOpen();

    manualSaveUserTraits();
    addManualUserTraitsFields();

    redirectIfNoEmail();
});

const WebflowCustomCode = (function() {

    function initContactForm() {
        analytics &&
            analytics.ready(function () {
                const trackForms = document.querySelectorAll(
                    'form[data-tracking="contact-form"]'
                );
                
                trackForms.forEach((form) => {
                    addHiddenTrackingFields(form);
                    SegmentEvent.trackContactFormOnSubmit(form);
                });
            });
    }

    function initDemoExperienceForm() {
        analytics &&
            analytics.ready(function () {
                const demoExperienceForm = document.querySelector(
                    'form[data-tracking="demo-experience-form"]'
                );

                if (demoExperienceForm) {
                    addHiddenTrackingFields(demoExperienceForm);

                    Object.entries(
                        getFromLocalStorage("ajs_user_traits")
                    ).forEach(([name, value]) =>
                        appendInputFieldToForm(demoExperienceForm, name, value)
                    );

                    SegmentEvent.trackDemoExperienceFormOnSubmit(demoExperienceForm);
                }
            });
    }

    function addHiddenTrackingFields(form) {
        const dataTracking = form.dataset.tracking;
        const user = analytics.user ? analytics.user() : null;
        const pageTitleCleaned = document.title.split("|")[0].trim();

        let values = {
            URL: window.location.origin + window.location.pathname,
            Query: window.location.search,
            "Segment User ID": user ? user.id() : "",
            "Segment Anonymous ID": user ? user.anonymousId() : "",
            "Current Page": pageTitleCleaned,
            Referrer: document.referrer,
            Tracking: dataTracking,
            "User Agent": navigator.userAgent,
            "marketing_data": JSON.stringify(
                ClickCollector.getClickIds()
            ),
        };

        Object.entries(values).forEach(([name, value]) =>
            appendInputFieldToForm(form, name, value)
        );
    }

    function setMarketingDataValue() {
        const hsMarketingDataInput = document.querySelector(
            'input[name="marketing_data"]'
        );

        if (hsMarketingDataInput) {
            hsMarketingDataInput.value = JSON.stringify(
                ClickCollector.getClickIds()
            );
        }
    }

    function initHsChatOpen() {
        if (window.HubSpotConversations) {
            window.HubSpotConversations.widget.close();
        } else {
            window.hsConversationsOnReady = [() => {
                window.HubSpotConversations.widget.close();
                
                const chatWithUsBtn = document.querySelector(
                    '[mag-element="hs-chat-open"]'
                );

                chatWithUsBtn &&
                    chatWithUsBtn.addEventListener("click", () => {
                        if (window.HubSpotConversations) {
                            window.HubSpotConversations.widget.open();
                        }
                    });
            }];
        }
    }

    function manualSaveUserTraits() {
        if (isInRequestDemoPages()) {
            const demoForm = document.querySelector('#email-form, [data-name="demo-experience"]');
            let userTraits = {};

            demoForm && demoForm.addEventListener("submit", (event) => {
                if (SegmentEvent.hasSegmentInitialized()) return;

                const formData = new FormData(event.target);
                const demoExpQuestions = SegmentEvent.getDemoExperienceQuestionKeys();

                for (const [key, value] of formData.entries()) {
                    if (key in demoExpQuestions) {
                        userTraits[demoExpQuestions[key]] = value;
                    } else {
                        let field = toSnakeCase(key);

                        if (field.includes("demo")) continue;
                        field = field === "phone_number" ? "phone" : field;

                        userTraits[field] = value;
                    }
                }

                localStorage.setItem("manual_user_traits", JSON.stringify(userTraits));
            })
        }
    }

    function addManualUserTraitsFields() {
        const manualUserTraits = getFromLocalStorage("manual_user_traits");
        const secondForm = document.querySelector('form[data-name="demo-experience"]');

        if (window.location.pathname.endsWith('/request-a-demo/details') && Object.keys(manualUserTraits).length > 0) {
            Object.entries(manualUserTraits).forEach(([name, value]) =>
                appendInputFieldToForm(secondForm, name, value)
            );
        }
    }

    function isInRequestDemoPages() {
        const pathname = window.location.pathname;
        
        return pathname.endsWith('/request-a-demo') ||
            pathname.endsWith('/request-a-demo/details');
    }

    function redirectIfNoEmail() {
        const userTraits = getFromLocalStorage('ajs_user_traits');
        const targetPaths = ['/request-a-demo/details', '/request-a-demo/success'];
        
        if (!targetPaths.includes(window.location.pathname)) return;

        if (!(Object.keys(userTraits).length > 0 && 'email' in userTraits && userTraits.email !== '')) {
            window.location.href = '/request-a-demo';
        }
    }

    return {
        initContactForm,
        initDemoExperienceForm,
        setMarketingDataValue,
        initHsChatOpen,
        manualSaveUserTraits,
        addManualUserTraitsFields,
        redirectIfNoEmail,
    };
})();