let hasCollectedIds = false;

window.addEventListener("DOMContentLoaded", (event) => {
    ClickCollector.recordClickIdsData();
});

const ClickCollector = (function () {
    const LS_KEY = "ikrs:click_collection";
    const defaultClickIds = ["gclid", "fbclid", "mscklid", "client_id", "fbp", "user_agent", "ip"];

    function getHasCollectedIds() {
        return hasCollectedIds;
    }

    function getClickIds() {
        return LS_KEY in localStorage
            ? JSON.parse(localStorage.getItem(LS_KEY))
            : { first: {}, last: {} };
    }

    function recordClickIdsData() {
        let clickIdsObj = getClickIds();

        const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));

        setAdditionalData().then((additionalData) => {
            const clickData = {
                ...urlParams,
                ...additionalData
            }
            
            for (const [key, value] of Object.entries(clickData)) {
                if (value && (defaultClickIds.includes(key) || key.startsWith("utm_"))) {
                    if (!(key in clickIdsObj.first)) {
                        clickIdsObj.first[key] = value;
                    }

                    clickIdsObj.last[key] = value;
                }
            };

            localStorage.setItem(LS_KEY, JSON.stringify(clickIdsObj));
            hasCollectedIds = true;
        });
    }

    async function setAdditionalData() {
        return {
            fbp: getCookieValue('_fbp'),
            user_agent: navigator.userAgent,
            ip: await getIp(),
        }
    }

    async function getIp() {
        try {
            const response = await fetch('https://api.ipify.org?format=json')
            const data = await response.json();

            return data.ip;
        } catch(e) {
            return '';
        }
    }

    return {
        recordClickIdsData,
        getHasCollectedIds,
        getClickIds,
    };
})();