const SegmentEvent = (function(){
    function trackContactFormOnSubmit(form, context = {}) {
        const targetProps = {
            "name": "name",
            "first name": "first_name",
            "last name": "last_name",
            "email": "email",
            "phone number": "phone",
            "phone": "phone",
            "mobile phone": "phone",
            "mobilephone": "mobilephone",
            "country": "country",
            "marketing_data": "marketing_data",
        };
        
        form.addEventListener("submit", (e) => {
            try {
                let traits = {};

                form
                    .querySelectorAll("input[data-name], select[data-name]")
                    .forEach((field) => {
                        const fieldName = field.dataset.name.toLowerCase();

                        if (Object.keys(targetProps).includes(fieldName)) {
                            const value = field.value;

                            if (fieldName == "name") {
                                traits = getFirstLastName(traits, value);
                            } else if (targetProps[fieldName] == "phone") {
                                traits[targetProps[fieldName]] = standardisedPhoneNumber(value);
                            } else if (fieldName === "country") {
                                traits["address"] = traits["address"] || {};
                                traits["address"]["country"] = value;
                            } else {
                                traits[targetProps[fieldName]] = value;
                            }
                        }
                    });

                traits["lead_type"] = "form";

                analytics.identify(traits, { context });

                analytics.track("Lead Submitted", traits, { context });
            } catch(e) {
                console.error(e);
            }
        });
    }

    function trackDemoExperienceFormOnSubmit(form, context = {}) {
        form.addEventListener("submit", (e) => {
            try {
                const questions = form.querySelectorAll("select");
                const questionsProperties = getDemoExperienceQuestionKeys();

                let traits = getFromLocalStorage("ajs_user_traits");

                for (let index = 0; index < questions.length; index++) {
                    const question = questions[index].name;

                    if (!(question in questionsProperties)) continue;

                    const value = questions[index].value;

                    traits[questionsProperties[question]] = value;
                }

                traits["lead_type"] = "form";

                analytics.identify(traits, { context });

                analytics.track("Demo Requested", traits, { context });
            } catch(e) {
                console.error(e);
            }
        });
    }

    function trackHSDemoExperienceFormOnSubmit(submissionValues) {
        try {
            const questionsProperties = getDemoExperienceQuestionKeys();

            let traits = getFromLocalStorage("ajs_user_traits");

            for (const [key, value] of Object.entries(submissionValues)) {
                if (!(key in questionsProperties)) continue;

                traits[questionsProperties[key]] = value;
            }

            traits["lead_type"] = "form";
            
            analytics.identify(traits, { context });

            analytics.track("Demo Requested", traits, { context });
        } catch (e) {
            console.error(e);
        }
    }

    function trackFormLeadSubmitted(formElem, dataFn) {
        try {
            if (typeof analytics?.trackForm === "function") {
                analytics.trackForm(formElem, "Lead Submitted", dataFn);
            } else {
                throw new Error("Segment analytics was not initialized.");
            }
        } catch(e) {
            console.error(e);
        }
    }

    function trackLeadSubmitted(traits) {
        analyticsTrack("Lead Submitted", traits);
    }

    function trackDemoBooked(traits) {
        analyticsTrack("Demo Booked", traits);
    }

    function analyticsTrack(eventName, traits) {
        try {
            if (typeof analytics?.track === "function") {
                analytics.track(eventName, traits);
            } else {
                throw new Error("Segment analytics was not initialized.");
            }
        } catch(e) {
            console.error(e)
        }
    }

    function hasSegmentInitialized() {
        return analytics && analytics.initialized;
    }

    function getDemoExperienceQuestionKeys() {
        return {
            "How-big-is-your-livestock-farming-area": "demo_grazing_area",
            "How-much-annual-rainfall-do-you-receive": "demo_rainfall",
            "How-do-you-currently-graze-your-stock": "demo_rotational_grazing",
            "How-much-do-you-know-about-MaiaGrazingPro-software-already": "demo_maiagrazingpro",
            "grazing_area_size_ac_cbr": "demo_grazing_area",
            "what_is_the_average_annual_rainfall__in_mm__for_this_property_": "demo_rainfall",
            "how_do_you_currently_graze_your_stock__": "demo_rotational_grazing",
            "how_much_do_you_know_about_maiagrazingpro_software_already__": "demo_maiagrazingpro",
        };
    }

    function identify(traits, context = {}) {
        if (typeof analytics?.identify === "function") {
            analytics.identify(traits, { context });
        } else {
            console.error("Segment analytics was not initialized.");
        }
    }

    return {
        trackContactFormOnSubmit,
        trackDemoExperienceFormOnSubmit,
        trackHSDemoExperienceFormOnSubmit,
        trackLeadSubmitted,
        trackFormLeadSubmitted,
        trackDemoBooked,
        hasSegmentInitialized,
        getDemoExperienceQuestionKeys,
        identify,
    };
})();