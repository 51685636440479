window.addEventListener("DOMContentLoaded", (event) => {
    HubspotFormEventListener.onFormSubmitted();
    HubspotFormEventListener.addIdCollectorJsonToHSForms();
});

const HubspotFormEventListener = (function () {
    function getBookingSchedule(data) {
        if (data.meetingsPayload && data.meetingsPayload.bookingResponse) {
            const { event } = data.meetingsPayload.bookingResponse;
            const { dateString, dateTime } = event;
            const timeString = convertDatetimeToTimeString(dateTime);
            
            return `${dateString} ${timeString}`
        }

        return '';
    }

    function onFormReady() {
        window.addEventListener("message", (event) => {
            const { data } = event;
            
            if (
                data.type === "hsFormCallback" &&
                data.eventName === "onFormReady"
            ) {
                const { id: formId } = data;

                initAutocompleteField(formId);
                setProductDemoDetails(formId);
            }
        });
    }

    function setProductDemoDetails(formId) {
        if (formId !== 'a39394d2-686d-4aed-8863-23462771aee2') return;

        const form = document.querySelector(`#hsForm_${formId}`);

        if (form) {
            const grazingArea = form.querySelector('[name="grazing_area_size_ac_cbr"]');
            const grazingAreaUnit = form.querySelector('[name="grazing_area_units"]');
            const rainfall = form.querySelector('[name="what_is_the_average_annual_rainfall__in_mm__for_this_property_"]');
            const rainfallUnit = form.querySelector('[name="rainfall_units"]');

            const nextBtn = document.querySelector('.hs-button.primary.large');

            if (grazingArea.value.trim() !== '' && grazingAreaUnit.value.includes('acres')) {
                grazingArea.value = getGrazingValues(grazingArea.value, grazingAreaUnit.value, true);
            }

            if (rainfall.value.trim() !== '' && rainfallUnit.value.includes('inches')) {
                rainfall.value = getGrazingValues(rainfall.value, rainfallUnit.value, true);
            }

            nextBtn && nextBtn.addEventListener('click', (e) => {
                e.preventDefault();

                if (grazingArea && grazingAreaUnit) {
                    grazingArea.value = getGrazingValues(grazingArea.value, grazingAreaUnit.value);
                }

                if (rainfall && rainfallUnit) {
                    rainfall.value = getGrazingValues(rainfall.value, rainfallUnit.value);
                }

                form.submit();
            });
        }
    }

    function initAutocompleteField(formId) {
        if (formId !== 'a5455491-1b1b-4797-a2c3-c08a4e73e086') return;

        const $form = $(`#hsForm_${formId}`);

        $form.prepend(`
            <div class="hs_address hs-address hs-fieldtype-text field hs-form-field" id="places-autocomplete-container">
                <label class="" for="places-autocomplete">
                    <span>Address</span>
                </label>
                <div class="input">
                    <input id="places-autocomplete" name="places-autocomplete" placeholder="Enter address" type="text" class="hs-input" inputmode="text" value="" />
                </div>
            </div>
        `);

        initializeAutocomplete();
        
        const geoFields = ['address', 'country', 'city', 'zip', 'state'];
        
        if ($form.find('[name="show_address_fields"]').is(':checked')) {
            showGeoFields(geoFields);
        } else {
            hideGeoFields(geoFields);
        }

        $form.find('[name="show_address_fields"]').change(function () {
            if ($(this).is(':checked')) {
                showGeoFields(geoFields);
            } else {
                hideGeoFields(geoFields);
            }
        });
    }

    function showGeoFields(geoFields) {
        geoFields.forEach(field => $(`[name=${field}]`).attr('type', 'text').closest('div.hs-form-field').show());
        $('#places-autocomplete-container').hide();
    }

    function hideGeoFields(geoFields) {
        geoFields.forEach(field => $(`[name=${field}]`).attr('type', 'text').closest('div.hs-form-field').hide());
        $('#places-autocomplete-container').show();
    }

    function onFormSubmitted() {
        window.addEventListener("message", (event) => {
            const { data } = event;

            if (data.meetingBookSucceeded) {
                const userTraits = getFromLocalStorage("ajs_user_traits");

                SegmentEvent.trackDemoBooked({
                    ...userTraits,
                    booking_schedule: getBookingSchedule(data),
                });
            }
            else if (
                data.type === "hsFormCallback" &&
                data.eventName === "onFormSubmitted"
            ) {
                const { id: formId, data: eventData } = data;
                const { submissionValues } = eventData;

                if (formId == 'a39394d2-686d-4aed-8863-23462771aee2') {
                    setRequestDemoDetailsLogic(submissionValues);
                } else if (formId == '651b8844-77e3-458e-aeac-8bb7f295bbfc') {
                    trackHSLeadForm(submissionValues, formId);

                    const locale = getLocale();
                    window.location.href = `${locale}/request-a-demo/details`;

                }                
            }
        });
    }

    function setRequestDemoDetailsLogic(submissionValues) {
        const {
            grazing_area_size_ac_cbr: q1,
            grazing_area_units,
            what_is_the_average_annual_rainfall__in_mm__for_this_property_: q2,
            rainfall_units,
            how_do_you_currently_graze_your_stock__: q3,
            how_much_do_you_know_about_maiagrazingpro_software_already__: q4,
        } = submissionValues;

        const grazingArea = getGrazingValues(q1, grazing_area_units);
        const rainfall = getGrazingValues(q2, rainfall_units);
        const locale = getLocale();

        if (q4 === "I'm ready to subscribe to MaiaGrazing Pro" ||
            grazingArea > 1000 ||
            (
                grazingArea >= 150 &&
                q3 !== "I practice set stocking and plan to continue doing so" &&
                q4 !== "I'm not interested in MaiaGrazing Pro"
            )) {
            
            submissionValues['grazing_area_size_ac_cbr'] = grazingArea;
            submissionValues['what_is_the_average_annual_rainfall__in_mm__for_this_property'] = rainfall;

            SegmentEvent.trackHSDemoExperienceFormOnSubmit(submissionValues);
            
            if (window.location.host.includes('webflow.io')) {
                window.location.href = `${locale}/request-a-demo/get-a-product-demo`
            } else {
                window.location.href = `${locale}/request-a-demo/get-a-product-demo`;
            }
            
            return;
        }

        window.location.href = `${locale}/request-a-demo/success`;
    }

    function trackHSLeadForm(submissionValues, formId) {
        if (
            !("firstname" in submissionValues) ||
            !("lastname" in submissionValues) ||
            !("email" in submissionValues)
        ) {
            console.error(
                "Data must have 'firstname', 'lastname', and 'email'."
            );
            return;
        }

        const { firstname, lastname, email, mobilephone, phone } = submissionValues;
        const phoneValue = mobilephone || phone || "";
        
        SegmentEvent.identify({
            first_name: firstname,
            last_name: lastname,
            email,
            phone: phoneValue ? standardisedPhoneNumber(phoneValue) : "",
        });

        const props = getLeadSubmittedProps(
            firstname,
            lastname,
            email,
            phoneValue,
            formId,
            submissionValues
        );

        SegmentEvent.trackLeadSubmitted(props);

    }

    function addIdCollectorJsonToHSForms() {
        const observer = new MutationObserver((mutationsList, observer) => {
            mutationsList.forEach((mutation) => {
                if (
                    mutation.type === "childList" &&
                    mutation.addedNodes.length
                ) {
                    const addedNode = mutation.addedNodes[0];
                    if (
                        addedNode.tagName === "FORM" &&
                        addedNode.action.includes("forms.hsforms.com")
                    ) {
                        const poll = setInterval(function () {
                            if (ClickCollector.getHasCollectedIds()) {
                                WebflowCustomCode.setMarketingDataValue();

                                clearInterval(poll);
                            }
                        }, 700);

                        observer.disconnect();
                    }
                }
            });
        });

        observer.observe(document.body, { childList: true, subtree: true });
    }

    function getGrazingValues(value, unit, reverse = false) {
        if (unit.includes('acres')) {
            if (reverse) return Math.round(parseFloat(value) * 2.4710538146);

            //acres to hectares
            return Math.round(parseFloat(value) * 0.404685642);

        } else if (unit.includes('inches')) {
            if (reverse) return Math.round(parseFloat(value) * 0.0393701);

            //inches to mm
            return Math.round(parseFloat(value) * 25.4);
        }

        return Math.round(parseFloat(value));
    }

    return {
        onFormSubmitted,
        addIdCollectorJsonToHSForms,
        onFormReady,
    };
})();
HubspotFormEventListener.onFormReady();