function appendInputFieldToForm(form, name, value) {
    if (!form) return;

    const hiddenInput = document.createElement("input");

    hiddenInput.type = "hidden";
    hiddenInput.name = name;
    hiddenInput.dataset.name = name;
    hiddenInput.value = value;

    form.appendChild(hiddenInput);
}

function getFirstLastName(traits, fullName) {
    if (typeof fullName === "string" && fullName != "") {
        const first_name = fullName.split(" ").slice(0, -1).join(" ");
        const last_name = fullName.split(" ").slice(-1).join(" ");

        return { ...traits, first_name, last_name };
    }

    return traits;
}

function splitName(fullName) {
    const trimmedName = fullName.trim();

    if (trimmedName === "") {
        return {
            firstName: "",
            lastName: "",
        };
    }

    const [firstName, ...lastNameArray] = trimmedName.split(" ");
    const lastName = lastNameArray.join(" ");

    return {
        firstName,
        lastName,
    };
}

function hashString(str) {
    let hash = 0;

    if (str.length === 0) {
        return hash.toString(16);
    }

    for (let i = 0; i < str.length; i++) {
        const chr = str.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
    }

    return (hash >>> 0).toString(16);
}

function getProductName() {
    if (location.origin.includes("mykajabi")) {
        return "grazing-compass";
    }

    return "grazing-app";
}

function getLeadSubmittedProps(
    firstName,
    lastName,
    email,
    phone,
    formId,
    formData = {}
) {
    const product = getProductName();
    const value = product === "grazing-app" ? 25 : 10;

    return {
        first_name: firstName,
        last_name: lastName,
        email,
        phone: phone ? standardisedPhoneNumber(phone) : "",
        lead_type: "form",
        form_id: formId,
        form_data: formData,
        value,
        currency: "AUD",
        order_id: hashString(email),
        product: product,
    };
}

function formDataToObject(formData) {
    return Object.fromEntries(formData.entries());
}

function getFromLocalStorage(key, defaultValue = {}) {
    if (!localStorage.getItem(key)) return defaultValue;

    return JSON.parse(localStorage.getItem(key));
}

function convertDatetimeToTimeString(datetime) {
    const date = new Date(datetime);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const formattedTime = `${hours}:${minutes}:${seconds}`;
    return formattedTime;
}

function standardisedPhoneNumber(phoneNumber) {
    phoneNumber = phoneNumber.replace(/[ ]/g, "").replace("(0)", "");
    phoneNumber = phoneNumber.replace(/^00/, "+");
    phoneNumber = phoneNumber.replace(/[^\d\+]/g, "").trim();

    if (!phoneNumber.startsWith("+")) {
        phoneNumber = "+" + phoneNumber;
    }

    return phoneNumber == "+" ? "" : phoneNumber;
}

function toSnakeCase(str) {
    str = str.toLowerCase();
    return str
        .replace(/[\s-]+/g, '_')
        .replace(/_+/g, '_');
}

function getCookieValue(name) {
    const targetKey = name + "=";
    const cookies = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        
        while (cookie.charAt(0) == ' ') cookie = cookie.substring(1, cookie.length);

        if (cookie.indexOf(targetKey) == 0) return cookie.substring(targetKey.length, cookie.length);
    }

    return null;
}

function mergeAndRemoveDuplicates(array1, array2) {
    const mergedArray = array1.concat(array2);
    const uniqueArray = [...new Set(mergedArray)];

    return uniqueArray;
}

function getUserTraits() {
    return Object.keys(getFromLocalStorage("ajs_user_traits")).length !== 0 ? getFromLocalStorage("ajs_user_traits") : getFromLocalStorage("manual_user_traits");
}

function checkValue(userTraits, question, value) {
    return userTraits[question] && userTraits[question] === value;
}

function getLocale() {
    return window.location.pathname.includes('en-us') ? '/en-us' : '';
}