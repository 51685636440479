window.addEventListener("DOMContentLoaded", (event) => {
    KajabiEvents.onFormSubmitEventListener();
});

const KajabiEvents = (function () {
    function getProps(formId) {
        const form = document.querySelector(`form[action*="forms/${formId}"]`);

        const { value: name } = form.querySelector(
            'input[name="form_submission[name]"]'
        );
        const { value: email } = form.querySelector(
            'input[name="form_submission[email]"]'
        );

        const { firstName, lastName } = splitName(name);

        return getLeadSubmittedProps(
            firstName,
            lastName,
            email,
            "",
            formId
        );
    }

    function onFormSubmitEventListener() {
        const targetFormId = "2148235955";
        const form = document.querySelector(
            `form[action*="forms/${targetFormId}"]`
        );

        if (form) {
            SegmentEvent.trackFormLeadSubmitted(form, () =>
                getProps(targetFormId)
            );
        }
    }

    return {
        onFormSubmitEventListener,
    };
})();
